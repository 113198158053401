import React, { useState, useEffect } from 'react';
import { db } from './firebaseConfig'; // Import Firestore as db
import { getDoc, getDocs, doc, collection } from 'firebase/firestore'; // Firestore imports
import './SearchComponent.css';
import axios from 'axios';
import EmailSubscription from './EmailSignUp';

function processTokens(tokenString) {
  const tokens = tokenString.split(',').map(token => {
    const [name, quantity] = token.split('[');
    return {
      // Remove all spaces from name
      name: name.replace(/\s/g, ''),
      // Remove ']', trim, and then remove all spaces from quantity
      quantity: quantity ? quantity.replace(']', '').replace(/\s/g, '') : null
    };
  }).filter(token => parseFloat(token.quantity) !== 0);
  return tokens;
}

const useTokenPrices = () => {
  const [tokenPrices, setTokenPrices] = useState({});

  useEffect(() => {
    const fetchTokenPrices = async () => {
      const snapshot = await getDocs(collection(db, "TokenPrices"));
      const prices = {};
      snapshot.forEach(doc => {
        // Remove special characters like asterisks from the document name
        const cleanDocId = doc.id.replace(/[*]/g, '');
        
        // Check if Price exists and is a string
        const priceData = doc.data().Price;
        if (typeof priceData === 'string') {
          console.log(priceData)
          // If it's a string, parse it to a float
          const price = parseFloat(priceData.replace(/,/g, ''));
          prices[cleanDocId] = isNaN(price) ? 0 : price;
        } else {
          // If Price is not a string or is missing, use a default value
          prices[cleanDocId] = 0;
        }
      });
      setTokenPrices(prices);
      console.log(tokenPrices)
    };

    fetchTokenPrices();
  }, []);

  return tokenPrices;
};

function formatNumber(num) {
  return new Intl.NumberFormat('en-US', { 
    minimumFractionDigits: 2, // Minimum decimal places
    maximumFractionDigits: 2 // Maximum decimal places
  }).format(num);
}

function formatTokenQuantity(num) {
  // Ensure the input is a number
  if (typeof num !== 'number') {
    num = Number(num);
    if (isNaN(num)) {
      throw new Error('Invalid number');
    }
  }

  // Convert the number to a string
  const numStr = String(num);

  // If the number is in scientific notation, convert it to a full string
  const fullNumStr = numStr.indexOf('e') !== -1 ? num.toPrecision(18).replace(/\.?0+$/, "") : numStr;

  // Split the number into integer and decimal parts
  const [integerPart, decimalPart] = fullNumStr.split('.');

  // If there is no decimal part, return the integer part
  if (!decimalPart) return integerPart;

  // Remove trailing zeros from the decimal part
  const trimmedDecimalPart = decimalPart.replace(/0+$/, '');

  // If the decimal part becomes empty after trimming, return just the integer part
  if (trimmedDecimalPart.length === 0) return integerPart;

  // Return the integer part and the trimmed decimal part
  return `${integerPart}.${trimmedDecimalPart}`;
}


function calculateTotalClaimSize(tokens, prices) {
  let total = 0;
  for (let token of tokens) {
    const tokenPrice = prices[token.name] || 0; // Use 0 if the price is not available

    // Convert token.quantity to a valid number, or use 0 if it's not valid
    let quantity = parseFloat(token.quantity);
    if (isNaN(quantity) || typeof token.quantity === 'undefined' || token.quantity === null) {
      quantity = 0;  // Default to 0 if not a valid number
    }
    // Check if tokenPrice is a valid number
    if (!isNaN(tokenPrice) && !isNaN(quantity)) {
      const value = quantity * tokenPrice;
      total += value;
      console.log(`TOTALToken: ${quantity} SPACE ${tokenPrice} total: ${value}`);
    } else {
      // Log or handle cases where one of the values is not a number
      console.log(`Invalid data - token: ${token.name}, quantity: ${token.quantity}, price: ${tokenPrice}`);
    }
    console.log("TOTAL: " + total);
  }
  return total;
}

function SearchComponent() {
  const [customerNumber, setCustomerNumber] = useState('');
  const [customerData, setCustomerData] = useState(null);
  const [claimSize, setClaimSize] = useState(0);
  const [isChecked, setIsChecked] = useState(false);
  const [selectedOption, setSelectedOption] = useState('ftxfilings');
  const tokenPrices = useTokenPrices(); // Fetch all token prices once
  const [sortOrder, setSortOrder] = useState('desc'); // 'desc' for descending, 'asc' for ascending
  const [sortByValue, setSortByValue] = useState(false); // New state, false for sorting by name, true for sorting by value

  // Toggle function to switch sortOrder
  const toggleSortOrder = () => {
    setSortByValue(!sortByValue); // Simply toggle the sorting criteria
  };

  useEffect(() => {
    // Reset searchPerformed when the selectedOption changes
    setSearchPerformed(false);
  }, [selectedOption]); // Dependency array with selectedOption
  const handleDropdownChange = (event) => {
    setSelectedOption(event.target.value);
    setCustomerData(null);
    setSearchPerformed(false);  // Reset searchPerformed on option change
  };
  // Set default values for collectionName and TokenName
  let collectionName = 'ftxfilings'; // Default collection
  let TokenName = 'TotalTokens'; // Default token name
  if (selectedOption === 'march') {
    collectionName = 'CustomerAccountsMarchData';
    TokenName = 'Token';
  } else if (selectedOption === 'january') {
    collectionName = 'CustomerAccountsJanSchFData';
    TokenName = 'Token';
  } else if (selectedOption === 'june') {
    collectionName = 'ftxfilings';
    TokenName = 'TotalTokens';  }

    const handleCustomerNumberChange = (e) => {
      setCustomerNumber(e.target.value);
      setSearchPerformed(false);  // Reset searchPerformed on customer number change
    };
    
  const handleSearch = async () => {
    
    const customerRef = doc(db, collectionName, customerNumber);
    const customerDoc = await getDoc(customerRef);
    if (customerDoc.exists()) {
      const data = customerDoc.data();
      data.processedTokens = processTokens(data[TokenName]);
      setCustomerData(data);
      // Calculate the claim size using the updated prices
      const calculatedClaimSize = calculateTotalClaimSize(data.processedTokens, tokenPrices);
      setClaimSize(calculatedClaimSize); // Update the state for claimSize
    } else {
      console.log(customerDoc.data)
      setCustomerData("NO CUSTOMER DATA FOR ", selectedOption);
    }
    setSearchPerformed(true);

  };
  const noDataMessage = `No Data For ${selectedOption.charAt(0).toUpperCase() + selectedOption.slice(1)} Schedules`;
  const [searchPerformed, setSearchPerformed] = useState(false);

  const sortedTokens = customerData && customerData.processedTokens
    ? [...customerData.processedTokens].sort((a, b) => {
        if (sortByValue) {
          // Sort by token value
          const aValue = tokenPrices[a.name] ? tokenPrices[a.name] * parseFloat(a.quantity) : 0;
          const bValue = tokenPrices[b.name] ? tokenPrices[b.name] * parseFloat(b.quantity) : 0;
          return bValue - aValue;
        } else {
          // Sort alphabetically by token name
          return a.name.localeCompare(b.name);
        }
      })
    : [];

  return (
    <div className="container">
      <div className="search-box">
        <h1>Customer Code</h1>
        <input
          className="search-input"
          type="text"
          value={customerNumber}
          onChange={handleCustomerNumberChange}
          onKeyDown={(e) => { if (e.key === 'Enter') handleSearch(); }}
          placeholder="Enter Customer Code"
        />
        <select className="custom-dropdown" value={selectedOption} onChange={handleDropdownChange}>
          <option value="june">View June Sch F Data</option>
          <option value="march">View March Sch F Data</option>
          <option value="january">View Jan Sch F Data</option>
        </select>
        <button className="search-button" onClick={handleSearch}>Search</button>
      </div>

      {searchPerformed && (
        customerData && Array.isArray(customerData.processedTokens) ? (
          customerData.processedTokens.length > 0 ? (
          <div className="results">
            
            <table className="customer-table">
              <tbody>
              <tr><td><b>Minimum Claim Amount</b></td><td><b>{claimSize ? new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(claimSize) : "N/A"}</b></td></tr>
                <tr><td>Unique Customer Code</td><td>{customerNumber}</td></tr>
                <tr><td>Transfer Of Claim Docket Number (updated as of Docket No. 26306)</td><td>{customerData.Transfer_Record || "N/A"}</td></tr>
                <tr><td>Page Number</td><td>{customerData.Page_Number || "N/A"}</td></tr>
                <tr><td>Docket Number</td><td>{customerData.Docket_Number || "N/A"}</td></tr>
                <tr><td>CUD Indicator</td><td>{customerData.Indicator || customerData.Contingency || "N/A"}</td></tr>
                <tr><td>Earn Indicator</td><td>{customerData.EarnIndicator || customerData.Earn || "N/A"}</td></tr>
                  <tr><td>Token / Fiat in Lend</td><td>{customerData.TokenLend || customerData.Token_In_Lend || "N/A"}</td>
                </tr>
                </tbody>
              </table>
              <div className="sort-by-container">
              <div className="toggle-switch">
                <input
                  id="sortOrderToggle"
                  className="sort-order-toggle"
                  type="checkbox"
                  checked={sortByValue}
                  onChange={toggleSortOrder}
                />
                <label htmlFor="sortOrderToggle" className="toggle-label"></label>
              </div>
                            <span>Sort by Petition Date Value</span> {/* The text next to the toggle */}

              </div>

              <table className="tokens-table">
                <thead>
                  <tr>
                    <th>Coin</th>
                    <th>Quantity</th>
                    <th>Petition Date Pricing</th>
                    <th>Petition Date Value</th>
                  </tr>
                </thead>
                <tbody>
              {sortedTokens.map((token, index) => (
                <tr key={index}>
                  <td>{token.name}</td>
                  <td>{formatTokenQuantity(token.quantity)}</td>
                  <td>{tokenPrices[token.name] !== undefined ? tokenPrices[token.name] : "Fetching..."}</td>
                  <td>{tokenPrices[token.name] !== undefined ? formatNumber(tokenPrices[token.name] * parseFloat(token.quantity)) : "Fetching..."}</td>
                </tr>
              ))}
            </tbody>
              </table>
            </div>
          ) : (
            <div className="no-data-message">
              {noDataMessage}
            </div>
          )
        ) : (
          <div className="no-data-message">{noDataMessage}</div>
        )
      )}
    </div>
    
  );
}

export default SearchComponent;